export default [
  {
    path: '/admin',
    name: 'AdminView',
    meta: {layout: 'empty', userRole: [1]},
    component: () => import('@/app/admin/views/AdminView'),
    children: [
      {
        path: 'teachers',
        name: 'AdminTeachersView',
        component: () => import('@/app/admin/views/AdminTeachersView')
      },
      {
        path: 'teachers/create',
        name: 'AdminTeachersCreateView',
        props: true,
        component: () => import('@/app/admin/views/AdminTeachersCreateView')
      },
      {
        path: 'teachers/edit/:id',
        name: 'AdminTeachersEditView',
        props: true,
        component: () => import('@/app/admin/views/AdminTeachersEditView')
      },
      {
        path: 'students',
        name: 'AdminStudentsView',
        component: () => import('@/app/admin/views/AdminStudentsView'),
      },
      {
        path: 'students/create',
        name: 'AdminStudentsCreateView',
        props: true,
        component: () => import('@/app/admin/views/AdminStudentsCreateView')
      },
      {
        path: 'students/edit/:id',
        name: 'AdminStudentsEditView',
        props: true,
        component: () => import('@/app/admin/views/AdminStudentsEditView')
      },

      {
        path: 'logs',
        name: 'AdminLogsView',
        props: true,
        component: () => import('@/app/admin/views/AdminLogsView')
      },
      {
        path: ':id',
        name: 'AdminLogsItemView',
        props: true,
        component: () => import('@/app/admin/views/AdminLogsItem')
      },
      {
        path: 'texts',
        name: 'AdminTextsView',
        props: true,
        component: () => import('@/app/admin/views/AdminTextsView')
      },
      {
        path: 'texts/:id',
        name: 'AdminTextsEditView',
        props: true,
        component: () => import('@/app/admin/views/AdminTextsEditView')
      },
      {
        path: 'groups',
        name: 'AdminGroupsView',
        props: true,
        component: () => import('@/app/admin/views/AdminGroupsView')
      },
      {
        path: 'clothes',
        name: 'AdminClothesView',
        props: true,
        component: () => import('@/app/admin/views/AdminClothesView')
      },
      {
        path: 'clothes/create',
        name: 'AdminClothesCreateView',
        props: true,
        component: () => import('@/app/admin/views/AdminClothesCreateView')
      },
      {
        path: 'clothes/edit/:id',
        name: 'AdminClothesEditView',
        props: true,
        component: () => import('@/app/admin/views/AdminClothesEditView')
      },
      {
        path: 'materials',
        name: 'AdminMaterialsView',
        props: true,
        component: () => import('@/app/admin/views/AdminMaterialsView')
      },
      {
        path: 'locations',
        name: 'AdminLocationsView',
        props: true,
        component: () => import('@/app/admin/views/AdminLocationsView')
      },
      {
        path: 'locations/:id',
        name: 'AdminLocationsEditView',
        props: true,
        component: () => import('@/app/admin/views/AdminLocationsEditView')
      },
      {
        path: 'news',
        name: 'AdminNewsView',
        props: true,
        component: () => import('@/app/admin/views/AdminNewsView')
      },
      {
        path: 'news/create',
        name: 'AdminNewsCreateView',
        props: true,
        component: () => import('@/app/admin/views/AdminNewsCreateView')
      },
      {
        path: 'news/edit/:id',
        name: 'AdminNewsEditView',
        props: true,
        component: () => import('@/app/admin/views/AdminNewsEditView')
      },
      {
        path: 'it-lessons',
        component: () => import('@/app/admin/views/AdminItLessonsView'),
        children: [
          {
            path: 'list',
            name: 'AdminItLessonsView',
            component: () => import('@/app/admin/views/AdminItLessonsListView'),
            props: true
          },
          {
            path: 'list/:id',
            name: 'AdminItLessonsItemView',
            component: () => import('@/app/admin/views/AdminItLessonsItemView'),
            props: true,
            redirect: { name: 'AdminItLessonsItemQuizListView' },
            children: [
              {
                path: 'quizes',
                name: 'AdminItLessonsItemQuizListView',
                component: () => import('@/app/admin/views/AdminItLessonsItemQuizListView'),
              },
              {
                path: 'quizes/:quizId',
                name: 'AdminItLessonsItemQuizItemView',
                component: () => import('@/app/admin/views/AdminItLessonsItemQuizItemView'),
                props: true
              },
              {
                path: 'questions/:questionId/',
                name: 'AdminItLessonsItemQuizAnswersView',
                component: () => import('@/app/admin/views/AdminItLessonsItemQuizAnswersView'),
                props: true
              }
            ]
          }
        ],
      },
      {
        path: 'it-courses',
        name: 'AdminItCoursesView',
        props: true,
        component: () => import('@/app/admin/views/AdminItCoursesView')
      },
      {
        path: 'it-categories',
        name: 'AdminItProjectCatView',
        props: true,
        component: () => import('@/app/admin/views/AdminItProjectCatView')
      },
      {
        path: 'it-shop',
        name: 'AdminItShopView',
        props: true,
        component: () => import('@/app/admin/views/AdminItShopView')
      },
      {
        path: 'tasks',
        component: () => import('@/app/admin/views/AdminTasksView'),
        children: [
          {
            path: '',
            name: 'AdminTasksView',
            component: () => import('@/app/admin/views/AdminTasksListView')
          },
          {
            path: 'create',
            name: 'AdminTasksCreateView',
            component: () => import('@/app/admin/views/AdminTasksCreateView')
          },
          {
            path: 'edit/:id',
            name: 'AdminTasksEditView',
            props: true,
            component: () => import('@/app/admin/views/AdminTasksEditView')
          },
        ]
      },
    ]
  },
]
