import { ref } from 'vue'

const sounds = {
  audio: [
    {
      name: 'win',
      url: '/storage/sounds/win.mp3'
    },
    {
      name: 'message',
      url: '/storage/sounds/message.mp3'
    },
    {
      name: 'select',
      url: '/storage/sounds/select.mp3'
    },
    {
      name: 'timer',
      url: '/storage/sounds/timer.mp3'
    },
    {
      name: 'error',
      url: '/storage/sounds/error.mp3'
    },
    {
      name: 'beep',
      url: '/storage/sounds/beep.mp3'
    },
    {
      name: 'card',
      url: '/storage/sounds/memo/card.mp3'
    },
    {
      name: 'invite',
      url: '/storage/sounds/invite.mp3'
    },
    {
      name: 'invite',
      url: '/storage/sounds/metronom-klack.mp3'
    }
  ],
  sprites: [
    {
      name: 'metronome',
      url: '/storage/sounds/sprites/metronome.mp3',
      items: {
        click1: [0, 809.795918367347],
        click2: [2000, 992.6530612244898],
        click3: [4000, 1253.8775510204082],
        cif1: [7000, 1645.714285714286],
        cif2: [10000, 2037.551020408163],
        no: [14000, 783.6734693877556],
        yes1: [16000, 3030.204081632654],
        yes2: [21000, 3030.204081632654],
      },
    },
    {
      name: 'numbers__en-US',
      url: '/storage/sounds/sprites/numbersEng.mp3',
      items: {
        0: [0, 448.95691609977325],
        1: [2000, 397.25623582766457],
        2: [26000, 308.77551020408237],
        3: [32000, 321.111111111108],
        4: [38000, 350.65759637188165],
        5: [44000, 397.59637188208785],
        6: [50000, 517.278911564624],
        7: [56000, 416.0544217687061],
        8: [62000, 332.83446712017906],
        9: [68000, 420.5895691609953],
        10: [4000, 334.1043083900228],
        11: [8000, 437.46031746031736],
        12: [10000, 375.1020408163264],
        13: [12000, 498.00453514739297],
        14: [14000, 479.0702947845808],
        15: [16000, 523.0612244897976],
        16: [18000, 561.043083900227],
        17: [20000, 602.0408163265323],
        18: [22000, 447.573696145124],
        19: [24000, 466.3945578231292],
        20: [28000, 308.77551020408237],
        30: [34000, 423.28798185940997],
        40: [40000, 397.1655328798178],
        50: [46000, 448.5034013605471],
        60: [52000, 564.1950113378655],
        70: [58000, 627.8911564625815],
        80: [64000, 366.89342403627734],
        90: [70000, 518.185941043086],
        100: [6000, 800.2947845804993],
        200: [30000, 672.2675736961463],
        300: [36000, 801.3832199546514],
        400: [42000, 753.1519274376421],
        500: [48000, 799.9999999999972],
        600: [54000, 682.0861678004562],
        700: [60000, 729.9999999999968],
        800: [66000, 692.1541950113408],
        900: [72000, 692.1541950113408],
        minus: [74000, 442.5396825396888],
        plus: [76000, 404.71655328798306],
      },
    },
    {
      name: 'numbers__ru-RU',
      url: '/storage/sounds/sprites/numbersRus.mp3',
      items: {
        1: [0, 357.68707482993193],
        2: [24000, 333.1065759637184],
        3: [30000, 363.76417233559977],
        4: [36000, 528.9569160997729],
        5: [42000, 527.9818594104313],
        6: [48000, 412.8571428571419],
        7: [54000, 322.2448979591803],
        8: [60000, 489.8412698412713],
        9: [66000, 417.0521541950052],
        10: [2000, 493.5827664399093],
        11: [6000, 690.7256235827664],
        12: [8000, 550.2040816326535],
        13: [10000, 574.1496598639451],
        14: [12000, 695.7369614512477],
        15: [14000, 738.231292517007],
        16: [16000, 571.1564625850336],
        17: [18000, 559.750566893424],
        18: [20000, 636.8253968253974],
        19: [22000, 620.7709750566898],
        20: [26000, 485.85034013605366],
        30: [32000, 408.52607709750544],
        40: [38000, 387.7097505668914],
        50: [44000, 597.7777777777789],
        60: [50000, 509.7052154195012],
        70: [56000, 425.7823129251719],
        80: [62000, 530.0000000000011],
        90: [68000, 513.2879818594063],
        100: [4000, 375.53287981859376],
        200: [28000, 506.9387755102035],
        300: [34000, 446.73469387755205],
        400: [40000, 567.7097505668911],
        500: [46000, 445.21541950113175],
        600: [52000, 445.6916099773238],
        700: [58000, 554.8752834467123],
        800: [64000, 602.5396825396854],
        900: [70000, 505.5328798185883],
        minus: [72000, 401.8820861678023],
      },
    },
    {
      name: 'countdown',
      url: '/storage/sounds/sprites/countdown.mp3',
      items: {
        3: [30, 640],
        2: [1030, 790],
        1: [2030, 500],
        cif: [2710, 995]
      }
    },
    {
      name: 'dino',
      url: '/storage/sounds/sprites/dino.mp3',
      items: {
        'jump': [0, 110],
        'collect': [500, 90],
        'lose': [1011, 280],
        'win': [1501, 2200]
      }
    }
  ]
}

export default function useSounds() {
  const audioCtx = ref(null)
  const audioBuffer = ref(null)
  const spriteSource = ref(null)
  const spriteItems = ref(null)

  const playSound = (name) => {
    const sound = sounds.audio.find(a => a.name === name)

    if (!sound) return

    const audio = new Audio(sound.url)

    if (audio) audio.play()
  }

  const initSprite = async (name) => {
    const sprite = sounds.sprites.find((s) => s.name === name)

    if (!sprite) return

    const url = sprite.url
    const AudioCtx = window.AudioContext || window.webkitAudioContext
    audioCtx.value = new AudioCtx()
    spriteItems.value = sprite.items

    // eslint-disable-next-line
    if (!spriteSource.value || spriteSource.value?.url.replace(/^.*\/\/[^\/]+/, '') !== url) {
      spriteSource.value = await fetch(url)
    }

    const arrayBuffer = await spriteSource.value.arrayBuffer()
    audioBuffer.value = await audioCtx.value.decodeAudioData(arrayBuffer)
  }

  const playSprite = (spriteItem, onended = null) => {
    if (!spriteItem) return null
    
    const startTime = spriteItems.value[spriteItem][0] / 1000
    const duration = spriteItems.value[spriteItem][1] / 1000
    const source = audioCtx.value.createBufferSource()

    source.buffer = audioBuffer.value
    source.connect(audioCtx.value.destination)
    source.start(audioCtx.value.currentTime, startTime, duration)
    if (onended !== null) {
      source.onended = onended
    }
  }

  return {
    playSound,
    initSprite,
    playSprite,
  }
}
